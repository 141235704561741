import React, { createContext, useState } from 'react';



const utmContext = createContext({});

const UtmProvider = (props) => {
  const [utm, setUtm] = useState();  
  const { children } = props;
  function addUtm(data){
      setUtm(data);
  }
  return (
    <utmContext.Provider
      value={{
        utm,
        addUtm,
      }}
    >
      {children}
    </utmContext.Provider>
  )
}

export default utmContext;
export { UtmProvider };