import React, { createContext, useState } from 'react';
import Cookies from "js-cookie";

const cookieContext = createContext({});

const CookieProvider = (props) => {
  const [bannerHidden, setBannerHidden] = useState(Cookies.get("gatsby-gdpr-google-tagmanager") || false);
  const { children } = props;
  return (
    <cookieContext.Provider
      value={{
        bannerHidden,
        setBannerHidden
      }}
    >
      {children}
    </cookieContext.Provider>
  )
}

export default cookieContext;
export { CookieProvider };