exports.components = {
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-templates-blog-index-jsx": () => import("./../../../src/templates/Blog/index.jsx" /* webpackChunkName: "component---src-templates-blog-index-jsx" */),
  "component---src-templates-blog-tag-index-jsx": () => import("./../../../src/templates/BlogTag/index.jsx" /* webpackChunkName: "component---src-templates-blog-tag-index-jsx" */),
  "component---src-templates-page-index-jsx": () => import("./../../../src/templates/page/index.jsx" /* webpackChunkName: "component---src-templates-page-index-jsx" */),
  "component---src-templates-resource-index-jsx": () => import("./../../../src/templates/Resource/index.jsx" /* webpackChunkName: "component---src-templates-resource-index-jsx" */),
  "component---src-templates-resource-tag-index-jsx": () => import("./../../../src/templates/ResourceTag/index.jsx" /* webpackChunkName: "component---src-templates-resource-tag-index-jsx" */)
}

